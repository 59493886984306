import jwt_decode from "jwt-decode";

/**
 * JWT token baked by strapi's "open in platform" / autologin flow will have
 * an adminId and adminEmail key set in the token. This helps us identify
 * when an admin is impersonating a user.
 * @returns Boolean
 */
export function isLoggedViaAdmin() {
    const jwt = jwt_decode(localStorage.getItem('accessToken'));
    return !!jwt.adminId;
}

// A "feature flagged" user SHOULDN'T see hidden things
export function isFeatureFlagged(user) {
    return !(user.email.endsWith('@gondola.travel') || isLoggedViaAdmin());
}