import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import { useSelector } from '@/store';
import { getCompanyInfo } from '@/slices/dashboard';
import useAuth from '@/hooks/useAuth';
import DashboardSidebar from './DashboardSidebar';
import { isFeatureFlagged } from '@src/utils/featureFlags';
import DashboardNavbar from './DashboardNavbar';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme, isSidebarHidden }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    paddingLeft: isSidebarHidden ? '0px' : '220px'
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')(({ theme }) => ({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  marginBottom: '100px', // required so that intercom widgets in the bottom right corner don't overlap with pagination
  WebkitOverflowScrolling: 'touch',
  [theme.breakpoints.down('md')]: {
    paddingTop: "64px"
  }
}));

const DashboardLayout = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { companyName } = useSelector(getCompanyInfo);
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [isSidebarHidden, setSidebarIsHidden] = useState(false);

  const userIsFeatureFlagged = isFeatureFlagged(user);

  useEffect(() => {
    if (location.pathname === '/dashboard/overview/review' && !isSidebarHidden) {
      setSidebarIsHidden(true);
    } else {
      setSidebarIsHidden(false);
    }
  }, [location.pathname]);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)} />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        companyName={companyName}
        userIsFeatureFlagged={userIsFeatureFlagged}
      />
      <DashboardLayoutWrapper isSidebarHidden={isSidebarHidden}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
