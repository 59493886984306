import strapiAxiosInstance from './strapiAxiosInstance';
import { updateMedia, uploadMedia } from './strapiMedia';
import _ from 'lodash';
import Qs from 'qs';

class ToursApi {
  /**
   * @param data
   * @return {Promise<*>}
   * @private
   */
  async uploadImagesOfData(data) {
    const uploadImage = async (media) => {
      const { file, fileInfo } = media;
      if (file instanceof File) {
        return uploadMedia({ file, fileInfo });
      }
      return updateMedia(file.id, fileInfo);
    };
    const mediaResults = await Promise.all(
      data.media.map((media) => uploadImage(media))
    );
    data.media = data.media.map((value, index) => mediaResults[index].id);
    if (data.highlights.every((highlight) => highlight.image)) {
      const results = await Promise.all(
        data.highlights.map((highlight) => uploadImage(highlight.image))
      );
      data.highlights = data.highlights.map((highlight, index) => ({ ...highlight, image: results[index].id }));
    }
    const infoBlockResults = await Promise.all(
      data.infoBlocks.map((infoBlock) => uploadImage(infoBlock.image))
    );
    data.infoBlocks = data.infoBlocks.map(
      (infoBlock, index) => ({ ...infoBlock, image: infoBlockResults[index].id })
    );
    return data;
  }

  /**
     *
     * @return {Promise<Array<import('@src/pages/tour/tour.types').Tour>}
     */
  async findAll(params = {}) {
    return (await strapiAxiosInstance('/tours', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      params,
    })).data;
  }

  async filter({sources = [], sort = 'newest', search = undefined, page = 1, pageSize = 30}) {
    const params = {
      _where: (sources || []).map(source => { return { source }; }),
      ...(search && { _q: search } || {}),
      _sort: sort == 'newest' ? 'updated_at:DESC' : 'updated_at:ASC',
      _start: (page - 1) * pageSize,
      _limit: pageSize,
    };

    const response = await strapiAxiosInstance(`/tours`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      params,
      paramsSerializer: function () {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });
    return { ...response };
  }

  async attachToursToReview(reviewId, tourIds) {
    const response = await strapiAxiosInstance.put(
      `/review-items/${reviewId}`,
      { "tours": tourIds },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return response.data.tours;
  }

  async getStats({sources = [], search = undefined}) {
    const params = {
      _where: ((sources || []).map(source => { return { source: source.toString() }; })),
    };
    // strapi v3 doesn't support _q on query transformations, so we work around it by adding a custom _where clause
    if (search) {
      params._where = params._where.concat([{_or: [{author_contains: search}, {title_contains: search}]}]);
    }
    const response = await strapiAxiosInstance(`/tours/stats`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      params,
      paramsSerializer: function () {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });
    return { ...response };
  }

  /**
     *
     * @param {number} id
     * @returns {Promise<import('@src/ui/pages/tours/tour.types').TourObject>}
     */
  async findById(id)  {
    return (await strapiAxiosInstance(`/tours/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    })).data;
  }

  /**
     *
     * @param {number} id
     * @param {Object.<string, any>} data
     * @returns {Promise<Object.<string, any>>}
     */
  async update(id, data) {
    const response = await strapiAxiosInstance(`/tours/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      data: data.media ? await this.uploadImagesOfData(_.cloneDeep(data)) : data,
    });
    return response.data;
  }

  /**
     *
     * @param {number} id
     * @return {Promise<void>}
     */
  async delete(id) {
    await strapiAxiosInstance(`/tours/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  }

  /**
     *
     * @returns {Promise<number>}
     */
  async count() {
    return (await strapiAxiosInstance('/tours/count', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    })).data;
  }

  /**
     *
     * @param {Object.<string, any>} data
     * @returns {Promise<Object.<string, any>>}
     */
  async create(data) {
    const response = await strapiAxiosInstance('/tours', {
      method: 'POST',
      data: await this.uploadImagesOfData(_.cloneDeep(data)),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data;
  }

  async findAllByReview(reviewId) {
    if (!reviewId) return [];

    const response = await strapiAxiosInstance(`/tours?_where[0][review_items.id]=${reviewId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    return response.data;
  }

  async updateSequence(updatedSequence) {
    const response = await strapiAxiosInstance('/tours/update-sequence', {
      method: 'POST',
      data: updatedSequence,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data;
  }
}

export const toursApi = new ToursApi();
