import strapiAxiosInstance from './strapiAxiosInstance';

class DashboardApi {
  /**
     * @public
     * @param {number} company
     * @param {Record<string, any>} data - website general info
     * @returns {Promise<void>}
     */
  async updateSiteInfo(company, data) {
    await strapiAxiosInstance(`/companies/${company}`, {
      method: 'PUT',
      data: {
        website: data
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
  }

  /**
     * @public
     * @param {number} company
     * @param {Record<string, any>} data - website general info
     * @returns {Promise<any>}
     */
  async updateCompanyInfo(company, data) {
    return (await strapiAxiosInstance(`/companies/${company}`, {
      method: 'PUT',
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    })).data;
  }

  /**
     *
     * @param {number} company - id of company
     * @returns {Promise<Record<string, any>>} company data
     */
  async getAllCompanyInfo(company) {
    return (await strapiAxiosInstance(`/companies/${company}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    })).data;
  }

  async getAllCompanyInfoNew(company) { // new api result format to match MaybeLoading states across app. Keeping old call intact due to its wide usage.
    return (await strapiAxiosInstance(`/companies/${company}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    })).data;
  }

  async getAllCompanyInfoSimple(company) {
    return (await strapiAxiosInstance(`/companies/simple/${company}`, {
      method: 'GET'
    })).data;
  }

  async submit() {
    await strapiAxiosInstance('/companies/submit-trigger', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
  }

  async generateAiCompanyContext() {
    return await strapiAxiosInstance('/companies/generate-company-ai-context', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
  }

  async generateImageCaption(image, tone, context) {
    return await strapiAxiosInstance('/companies/generate-image-caption', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      data: {
        image,
        tone,
        context,
      }
    });
  }
}

export const dashboardApi = new DashboardApi();
